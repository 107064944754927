import moment from "moment";

export const formatter = {
  // Epoch milliseconds to local time
  epochMsToLocalTime(value, format) {
    return value ? moment.unix(value/1000).format(format) : value
  },
  date(value, format) {
    return value ? moment(value).format(format) : value
  },
  todayYesterday(value, format) {
    moment.locale("en")
    return value ? moment(value).calendar(null, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      lastWeek: format,
      sameElse: format,
    }) : ""
  }
}
