import axios from "axios";
import store from "../../store";
import { session } from "@/_services";

export const membershipService = {
  getListMembership,
  getListUpgrade,
  getValidPayment,
  checkoutMembership,
  upgradeMembership,
  cancelCheckout,
  getLatestOrder,
  getTrxList,
}

async function getListMembership() {
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "users/reseller/membership",
      {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getListUpgrade() {
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "users/reseller/get_upgrade_package", {},
      {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken,
          "Content-Type": "application/json",
        },
      }
    );
    if(response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getValidPayment(data) {
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "users/reseller/validpayment",
      data,
      {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function checkoutMembership(membership) {
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "users/reseller/checkout",
      membership,
      {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken
        },
      }
    );
    if(response.status === 200) {
      session()
    }
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function upgradeMembership(membership) {
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "users/reseller/checkout_upgrade",
      membership,
      {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken
        },
      }
    );
    if(response.status === 200) {
      session()
    }
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function cancelCheckout(order_number) {
  try {
    const response = await axios.post(process.env.VUE_APP_API_URL + 'users/reseller/cancel_order', {
      order_number
    }, {
      headers: {
        api_key: process.env.VUE_APP_API_KEY,
        token: store.getters.getUserToken
      }
    })
    if(response.status === 200) {
      session()
    }
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function getLatestOrder() {
  try {
    const response = await axios.post(process.env.VUE_APP_API_URL + 'users/reseller/latestorder', {
      member_id: store.getters.getUserData.id
    }, {
      headers: {
        api_key: process.env.VUE_APP_API_KEY,
        token: store.getters.getUserToken
      }
    })
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function getTrxList(offset) {
  try {
    const response = axios.get(process.env.VUE_APP_API_URL + 'users/reseller/transaction_order?offset=' + offset + "&limit=5", {
      headers: {
        api_key: process.env.VUE_APP_API_KEY,
        token: store.getters.getUserToken
      }
    })
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}