// import Vue from 'vue'

export const tourSettings = {
  highlight: true,
  labels: {
    buttonSkip: 'Lewati',
    buttonPrevious: 'Sebelumnya',
    buttonNext: 'Lanjut',
    buttonStop: 'OK, Mengerti'
  },
  enabledButtons: {
    buttonSkip: true,
    buttonPrevious: true,
    buttonNext: true,
    buttonStop: true
  },
  startTimeout: 0,
  stopOnTargetNotFound: true,
  useKeyboardNavigation: true,
  enabledNavigationKeys: {
    escape: true,
    arrowRight: true,
    arrowLeft: true
  },
  debug: false
}

// export const tourHelper = {
//   // get step(s) info
//   currentStep(name) {
//     return Vue.prototype.$tours[name].currentStep
//   },
//   numberOfSteps (name) {
//     return Vue.prototype.$tours[name]._props.steps.length
//   },
//   // check tour step(s) condition
//   isFirst (name) {
//     return this.currentStep(name) === 0
//   },
//   isLast (name) {
//     return this.currentStep(name) === this.numberOfSteps(name) - 1
//   },
//   isRunning (name) {
//     return this.currentStep(name) > -1 && this.currentStep(name) < this.numberOfSteps(name)
//   },
//   // step methods
//   startTour(name) {
//     Vue.prototype.$tours[name].start()
//   },
//   nextStep (name) {
//     Vue.prototype.$tours[name].nextStep()
//   },
//   previousStep (name) {
//     Vue.prototype.$tours[name].previousStep()
//   },
//   skip (name) {
//     Vue.prototype.$tours[name].skip()
//   },
//   finish (name) {
//     Vue.prototype.$tours[name].finish()
//   },
// }
