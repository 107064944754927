export const cookieMan = {
  put(key, value, expires) {
    document.cookie = `${key}=${value}; ${expires ? `expires=${expires};` : ''} path=/`
  },
  get(key) {
    const name = key + "=";
    //* OLD
    // const decodedCookie = decodeURIComponent(document.cookie);
    //* NEW (Prevent error "malformed URI sequence" if there's a percent symbol in cookie)
    const decodedCookie = decodeURIComponent(encodeURIComponent(document.cookie));
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  remove(key) {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
  },
}
