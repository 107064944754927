const defaulTitle = "Situs Perhiasan Emas Berlian Online Terpercaya | Whizliz.com"
const defaultDescription = "Toko perhiasan emas berlian online terpercaya. Kalung, cincin, anting, gelang, liontin, logam mulia dari Juene, Aurum, Lino, Lotus, Ayu, Antam, Lotusarchi."
const defaultImage = require('@/assets/img/whizliz-logo.png')
const defaultType = "website"
const defaultPrice = "0"
const defaultSiteName = "Whizliz"

export const setMetainfo = (title, description, image, type) => ({
  title: title ?? defaulTitle,
  meta: [
    {vmid: 'description', name: 'description', content: description ?? defaultDescription},
    // facebook
    {vmid: 'og:title', property: 'og:title', content: title ?? defaulTitle},
    {vmid: 'og:description', property: 'og:description', content: description ?? defaultDescription},
    {vmid: 'og:site_name', property: 'og:site_name', content: defaultSiteName},
    {vmid: 'og:image', property: 'og:image', content: image ?? defaultImage},
    {vmid: 'og:type', property: 'og:type', content: type ?? defaultType},
    
    // twitter
    {vmid: 'twitter:title', name: 'twitter:title', content: title ?? defaulTitle},
    {vmid: 'twitter:description', name: 'twitter:description', content: description ?? defaultDescription},
    {vmid: 'twitter:image', name: 'twitter:image', content: image ?? defaultImage},
  ],
})

export const setMetaInfoProduct = (title, description, image, type, price) => ({
  title: title ?? defaulTitle,
  meta: [
    {vmid: 'description', name: 'description', content: description ?? defaultDescription},
    // facebook
    {vmid: 'og:title', property: 'og:title', content: title ?? defaulTitle},
    {vmid: 'og:description', property: 'og:description', content: description ?? defaultDescription},
    {vmid: 'og:image', property: 'og:image', content: image ?? defaultImage},
    {vmid: 'og:type', property: 'og:type', content: type ?? defaultType},
    
    // twitter
    {vmid: 'twitter:title', name: 'twitter:title', content: title ?? defaulTitle},
    {vmid: 'twitter:description', name: 'twitter:description', content: description ?? defaultDescription},
    {vmid: 'twitter:image', name: 'twitter:image', content: image ?? defaultImage},

    // if type is product
    {vmid: 'product:price:amount', property: type == 'product' ? 'product:price:amount' : null, content: price ?? defaultPrice},
    {vmid: 'product:price:currency', property: type == 'product' ? 'product:price:currency' : null, content: "IDR"},
    {vmid: 'twitter:label1', name: type == 'product' ? 'twitter:label1' : null, content: 'Price'},
    {vmid: 'twitter:data1', name: type == 'product' ? 'twitter:data1' : null, content: `IDR ${price ? price.toLocaleString('id-ID') : defaultPrice}`},
  ],
})
