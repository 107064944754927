<template>
  <router-view v-if="/\/webview|\/product-catalog/i.test(path)" />
  <!-- <router-view v-if="path.indexOf('/webview') >= 0" /> -->
  <App v-else />
</template>
<script>
const App = () => import('./App');

export default {
  name: 'Wrapper',
  components: {
    App
  },
  computed: {
    path() {
      return this.$route.path
    }
  },
}
</script>
