import store from "../store"
import { factories } from "@/api.js";
import { cookieMan } from '@/_helpers';

const mapData = (data) => data.map(el => {
  // NEW
  if (el.bundled_product) el.bundled_product = JSON.parse(el.bundled_product)
  el.variant_has_stock = el.product_variance_attribute.total_stock > 0 // check variant stock
  el.selected = el.checkout_validity
  return el
})

export const userHelper = {
  async setCartList(item) {
    try {
      if (item) return mapData([item])

      const res = await factories.user.getCart();
      if (item || res.status === 200)
        store.dispatch("setShoppingBag", mapData(res.data.data))
    } catch (e) {
      console.error(e)
    }
  },
  setGoogleOneTapCooldown(minutes = 10) {
    const expTime = (date => date.setTime(date.getTime() + minutes * 60 * 1000))(new Date())
    const cookieValue = cookieMan.get('g_state')
    if (cookieValue) cookieMan.put('g_state', cookieValue, new Date(expTime).toUTCString())
  }
}
