// import config from 'config';
// import { authHeader } from '../_helpers';
import axios from "axios";
import store from "../store";
import { toaster } from "../_helpers";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const apiUrl = process.env.VUE_APP_API_URL;
const vapidKey = process.env.VUE_APP_VAPID_KEY;
// const firebaseAuthKey = process.env.VUE_APP_FIREBASE_AUTH;
const headers = (token) => {
  const header = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": "application/json"
  }
  if (token) header.token = token
  return {
    headers: header
  }
}
const token = () => store.getters.getUserToken;

export const session = async () => {
  try {
    const res = await axios.get(apiUrl + "users/profile/member_reseller", headers(token()))
    if(res.status === 200) {
      const data = res.data.data
      store.dispatch('setUserData', data.member)
      store.dispatch('setResellerData', data.reseller_data)
      return data
    }
  } catch (e) {
    if (e.response) {
      localStorage.removeItem('token')
      store.dispatch('resetUserState')
    } else {
      toaster.make('We might be down or please check your internet connection', 'danger', 'Could not connect to Whizliz API')
    }
  }
}

export const userService = {
  login,
  logout,
  logoutAll,
  socialLogin,
  signUp,
  signUpWithReferral,
  signUpMerchant,
  activateUser,
  resendActivation,
};

function login(username, password) {
  return axios.post(apiUrl + "users/session/login", {
      email: username,
      password: password,
      device_model: "WEB",
      device_type: "WEB",
      device_id: "",
    }, headers())
    .then(async (res) => {
      const data = res.data.data
      localStorage.setItem("token", data.access_token)
      store.dispatch('setUserToken', data.access_token)
      store.dispatch('setUserData', data.profile_data)
      store.dispatch('setResellerData', data.reseller_data)
      firebase.analytics().logEvent("login")
      return data
    })
    .catch(e => {
      if(e.response) return e.response
      else toaster.make('We might be down or please check your internet connection', 'danger', 'Could not connect to Whizliz API')
    });
}

function signUp(signUpData) {
  return axios.post(apiUrl + "users/session/signup", {
      email: signUpData.email,
      full_name: signUpData.fullName,
      password: signUpData.password,
      confirm_password: signUpData.confirmPassword,
      birth_date: signUpData.birthDate,
      gender: signUpData.gender,
      phone: signUpData.phone,
      device_model: "WEB",
      device_type: "WEB",
      device_id: "",
    }, headers())
    .then(response => {
      handleResponse(response)
      localStorage.setItem('email_login', signUpData.email)
      localStorage.setItem('password_login', signUpData.password)
      return response.data.message
    })
    .catch(e => {
      // return error.response.data.message
      if(e.response) return e.response.data.message
      else toaster.make('We might be down or please check your internet connection', 'danger', 'Could not connect to Whizliz API')
    })
}

function signUpWithReferral(signUpData) {
  return axios.post(apiUrl + "users/session/signup", {
      email: signUpData.email,
      full_name: signUpData.full_name,
      password: signUpData.password,
      confirm_password: signUpData.confirm_password,
      birth_date: signUpData.birth_date,
      gender: signUpData.gender,
      phone: signUpData.phone,
      input_referral_code: signUpData.input_referral_code,
      device_model: "WEB",
      device_type: "WEB",
      device_id: "",
    }, headers())
    .then(response => {
      handleResponse(response)
      localStorage.setItem('email_login', signUpData.email)
      localStorage.setItem('password_login', signUpData.password)
      return response.data.message
    })
    .catch(error => {
      return error.response.data.message
    })
}
function signUpMerchant(signUpMerchantData) {
  return axios.post(apiUrl + "merchants/session/signup", {
      name: signUpMerchantData.name,
      url: signUpMerchantData.url,
      email: signUpMerchantData.email,
      account_number: signUpMerchantData.account_number,
      account_alias: signUpMerchantData.account_alias,
      password: signUpMerchantData.password,
      confirm_password: signUpMerchantData.confirm_password,
      gender: "",
      phone: signUpMerchantData.phone,
      birth_date: "",
      region_id: signUpMerchantData.region_id,
      district_id: signUpMerchantData.district_id,
      township_id: signUpMerchantData.township_id,
      address: signUpMerchantData.address,
      postal_code: signUpMerchantData.postal_code,
      shippings: signUpMerchantData.shippings
    }, headers())
    .then(response => {
      handleResponse(response)
      return response.data.message
    })
    .catch(e => {
      // return error.response.data.message
      if(e.response) return e.response.data.message
      else toaster.make('We might be down or please check your internet connection', 'danger', 'Could not connect to Whizliz API')
    })
}

function socialLogin(socialApp, userData, authType) {
  const profile = userData.getBasicProfile()
  return axios.post(apiUrl + "users/session/" + authType + "/" + socialApp + "_v2", {
    email: profile.getEmail(),
    full_name: profile.getName(),
    access_token: userData.getAuthResponse().access_token,
    device_model: "WEB",
    device_type: "WEB",
    google_user_id: profile.getId(),
    device_id: "",
  }, headers())
    .then(async (res) => {
      const data = res.data.data
      localStorage.setItem("token", data.access_token)
      store.dispatch('setUserToken', data.access_token)
      store.dispatch('setUserData', data.profile_data)
      store.dispatch('setResellerData', data.reseller_data)
      firebase.analytics().logEvent("login")
      return data
    })
    .catch(e => {
      if(e.response) return e.response
      else toaster.make('We might be down or please check your internet connection', 'danger', 'Could not connect to Whizliz API')
    });
}
async function logout() {
  try {
    const res = await axios.get(apiUrl + "users/session/logout", headers(token()))
    if(firebase.messaging.isSupported()) {
      const deviceToken = await firebase.messaging().getToken({vapidKey}).catch(() => {})
      const userQbId = store.state.userData.quickblox_id
      if(userQbId && deviceToken) {
        axios.post(process.env.VUE_APP_API_URL + "support/fcm/unsubscribe",
          {  
            topic: `qb-${userQbId}`,
            token: deviceToken
          },
          headers()
        )

      }
        // axios.post("https://iid.googleapis.com/iid/v1:batchRemove", {
        //   to: `/topics/${process.env.VUE_APP_API_URL.includes('staging') ? 'staging-' : ''}member-fe-` + userQbId,
        //   registration_tokens: [deviceToken]
        // }, {
        //   headers: {
        //     "Content-type": "application/json",
        //     authorization: "key=" + firebaseAuthKey,
        //   }
        // })
    }
    return res
  } catch (e) {
    console.error(e)
  }
}
function logoutAll() {
  axios.get(apiUrl + "users/session/logout_other_devices", headers(token()))
    .catch(e => console.error(e))
}
async function activateUser(code, reseller) {
  const endpoint = "users/session/activate" + (reseller ? '_reseller' : '')
  try {
    return await axios.patch(apiUrl + endpoint, {code}, headers())
  } catch (e) {
    return e.response
  }
}
function resendActivation(email, type = "member") {
  return axios.post(apiUrl + "users/session/resend_activation_" + type, {email}, headers())
}
function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
      } else {
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    }
  }
  return response;
}