import axios from "axios";
import store from "../store";

const headers = (token, contentType = "application/json") => {
  const header = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  if (token) header.token = token
  return { headers: header }
}

const token = () => store.getters.getUserToken;

export const cmsService = {
  async getPromotions() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/promotion', headers(token()))
    } catch (e) {
      console.error(e)
      return e
    }
  },

  async getBanner() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/banner', headers(token()))
    } catch (e) {
      console.error(e)
      return e
    }
  },
  
  async getAllBanner() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/allBanner', headers(token()))
    } catch (e) {
      console.error(e)
      return e
    }
  },
}
