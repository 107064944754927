import axios from "axios";
import store from "../store";

const headers = (token, contentType = "application/json") => {
  const header = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  if (token) header.token = token
  return { headers: header }
}

const token = () => store.getters.getUserToken;

export const addressService = {
  addAddress,
  editAddress,
  setPrimaryAddress,
  deleteAddress,
  getAvailableAddress,
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
};

async function addAddress(data) {
  try {
    const response = await axios.post(process.env.VUE_APP_API_URL + "users/profile/address", data, headers(token()))
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function editAddress(id, data) {
  try {
    const response = await axios.patch(process.env.VUE_APP_API_URL + "users/profile/address/" + id, data, headers(token()))
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function setPrimaryAddress(id) {
  try {
    const response = await axios.patch(process.env.VUE_APP_API_URL + "users/profile/setPrimaryAddress/" + id, {}, headers(token()))
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function deleteAddress(id) {
  try {
    const response = await axios.delete(process.env.VUE_APP_API_URL + "users/profile/address/" + id, headers(token()))
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function getAvailableAddress() {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + "users/profile/address/1", headers(token()))
    return response
  } catch (e) {
    console.error(e)
    return e.response
  }
}

async function getProvinceList() {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + "users/cms/state/region", headers())
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getDistrictList(id) {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + "users/cms/state/province/" + id, headers())
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function getSubDistrictList(id) {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + "users/cms/state/city/" + id, headers())
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}