import axios from "axios";
import QB from "quickblox/quickblox.min";
import store from "../store"
import moment from "moment"
import firebase from "@/api/firebase";

const apiUrl = process.env.VUE_APP_API_URL;
const apiKey = process.env.VUE_APP_API_KEY;

export const qbConfig = JSON.parse(process.env.VUE_APP_QB_CONFIG)
export const CONSTANTS = {
  DIALOG_TYPES: {
    PRIVATECHAT: 3,
    GROUPCHAT: 2,
    PUBLICCHAT: 1,
  },
  ATTACHMENT: {
    TYPE: "image",
    BODY: "[attachment]",
    MAXSIZE: 5 * 1024 * 1024, // set 5 megabytes,
    MAXSIZEMESSAGE: "Image is too large. Max size is 5 mb.",
  },
  NOTIFICATION_TYPES: {
    NEW_DIALOG: "1",
    UPDATE_DIALOG: "2",
    LEAVE_DIALOG: "3",
  },
}

export const qbHelper = {
  initQuickblox,
  // SESSION
  createSession,
  loginSession,
  connectChat,
  getSession,
  // DIALOG
  createDialog,
  getDialogs,
  setDialogs,
  joinToDialog,
  // MESSAGE
  getMessages,
  setMessages,
  sendMessage,
  sendSystemMessage, //* Message won't save in quickblox server
  createAndUpload,
  fillNewMessageParams,
  // MESSAGE STATUS
  sendDeliveredStatus,
  sendReadStatus,
  // OTHER
  getUserList,
  getRecipientUserId,
  scrollTo,
  clearQb,
}

function initQuickblox() {
  QB.init(
    qbConfig.appId,
    qbConfig.authKey,
    qbConfig.authSecret,
    qbConfig.accountKey,
    qbConfig.config
  )
}

function getSession() {
  return QB.service.getSession()
}

function createSession() {
  return QB.createSession((error, result) => {
    if(error) console.log("QB Create Session Error")
    else if(result) {
      console.log("QB Create Session Success")
      return loginSession()
    }
  })  
}

function loginSession() {
  const params = {
    email: store.getters.getUserData.email,
    password: "qbuser2021"
  }
  return QB.login(params, (error, result) => {
    if(error) console.log("QB Login Error");
    else if(result) {
      console.log("QB Login Success")
      return connectChat()
    }
  })  
}

function connectChat() {
  const userCredentials = {
    userId : store.getters.getUserData.quickblox_id,
    password : "qbuser2021"
  }
  return QB.chat.connect(userCredentials, (error, contactList) => {
    if(error) console.log("QB Connect Chat Error");
    else if(contactList){
      console.log("QB Connect Chat Success")
      store.dispatch('setQbSession', !store.getters.getQbSession)
    }
  })
}


async function createDialog(param1, param2, type) {
  try {
    let url = ""
    if(type === "product") url = `users/chat/prepare?merchant_id=${param1}&product_id=${param2}`; 
    else if(type === "order") url = `users/chat/prepare?merchant_id=${param1}&salesOrderSeller_id=${param2}`; 
    const response = await axios.get(apiUrl + url, {
      headers : {
        api_key : apiKey,
        token : store.getters.getUserToken,
      }
    })

    const member_quickblox_id = response.data.data.member_quickblox_id 
    const merchant_quickblox_id = response.data.data.merchant_quickblox_id 
    const previewLink = response.data.data.preview_link

    const params = {
      type : CONSTANTS.DIALOG_TYPES.PRIVATECHAT, // Private Dialog
      occupants_ids : [member_quickblox_id, merchant_quickblox_id]
    }

    QB.chat.dialog.create(params, (error, dialog) => {
      if(error) console.log("QB Create Dialog Error");
      else if(dialog) {
        console.log("QB Create Dialog Success");
        store.dispatch({
          type: "setIsLoading",
          key: "dialogList",
          value: false,
        });
        store.dispatch("pushNewDialog", dialog) // Push new dialog to state.dialogList
        store.dispatch("setCurrentDialog", dialog) // Auto select the new dialog
        store.dispatch("setMessagePreview", previewLink)
      } 
    })

  } catch (error) {
    console.error(error);
  }
}

function getDialogs() {
  const filter = {
    limit: 100,
    sort_desc: "updated_at",
    "type[in]": "3,2"
  };  
  QB.chat.dialog.list(filter, async (error, dialogs) => {
    if (error) console.log("QB Get Dialog(s) Error");
    else if(dialogs) {
      dialogs.items.forEach((dialog) => {
        if(dialog.xmpp_room_jid) joinToDialog(dialog) //For join to group chat dialog
      })

      const result = await setDialogs(dialogs.items)
      if(result) {
        store.dispatch({
          type: "setIsLoading",
          key: "dialogList",
          value: false,
        });
        store.dispatch("setDialogList", result)
      }
    }  
  })
}

function setDialogs(dialog) {
  const dialogRes = dialog.reduce((obj, item) => {
    obj[item._id] = item
    return obj;
  }, {})
  return dialogRes
}

function joinToDialog(dialog) {
  try {
    const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialog._id);
    QB.chat.muc.join(dialogJid, (error, result) => {
      if(error) console.log("QB Join Dialog to ", dialog.name ,"Error");
      else if(result) console.log("QB Join Dialog to", dialog.name ,"Success");
    })
  } catch (e) {
    console.error(e)
  }
}

function getMessages(dialogId) {
  // run loading animation when try to get message list
  store.dispatch({
    type: "setIsLoading",
    key: "messageList",
    value: true,
  });

  var params = {
    chat_dialog_id: dialogId,
    sort_desc: 'date_sent',
    skip: 0
  };

  QB.chat.message.list(params, async (error, messages) => {
      if(error) console.log("QB Get Message(s) Error")
      else {
          store.dispatch({
            type: "setIsLoading",
            key: "messageList",
            value: false,
          });
          // Send read status to opponent for the new message(s)
          messages.items.forEach((el) => {
            // If sender_id is opponent quickblox id & message all_read = false
            if(el.sender_id != store.getters.getUserData.quickblox_id && !el.all_read) {
              sendReadStatus(el.sender_id, el._id, el.chat_dialog_id)
            }
          })
          const resMessages = await setMessages(messages.items.reverse());
          if (resMessages) {
            store.dispatch("decreaseUnreadCounter", dialogId);
            store.dispatch("setMessageList", resMessages);
          }
          setTimeout(() => {
            scrollTo(document.querySelector('.dialog-chat-messages-list'), 'bottom');
          }, 200);
        // } 
      }
  });
}

async function setMessages(messages) {
  try {
    // for set messages purpose
    if(!Array.isArray(messages)) {
      const tmpMsg = messages
      messages = []
      messages.push(tmpMsg)
    }

    // set full name & status variable in each message
    messages = messages.map(message => {
      message.full_name = null
      message.status = ""
      return message
    })

    // Get all occupants ID
    const occupantsIds = messages.map((item) => {
      return item.sender_id;
    })

    // set full name by sender id to each message
    getUserList({
      field: 'id',
      value: occupantsIds,
      per_page: occupantsIds.length
    }, (res) => {
      messages = messages.map((message) => {
        message["full_name"] = message.sender_id == 0 ? "System Message" : res.find(el => el.id === message.sender_id).full_name || null; 
      })
    })

    messages.forEach((message) => {
      // if message has attachment
      if(message.attachments.length) {
        message.attachments.map((attachment) => {
          attachment.src = QB.content.publicUrl(attachment.id) +
          ".json?token=" +
          QB.service.getSession().token;
        })
      }
      // for group message list by date "DD/MM/YYYY" purpose by adding dateIds key in each message
      if (!(message.date_sent instanceof Date)) {
        const date = new Date(+message.date_sent * 1000)
        message.dateIds = moment(date).format("YYYYMMDD")
      }

      message["status"] = getMessageStatus(message);
    });

    // group message list by date "DD/MM/YYYY"
    const messagesRes = messages.reduce((acc, obj) => {
      var key = obj['dateIds'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    return messagesRes

  } catch (error) {
    console.error(error)
  }
}

async function sendSystemMessage(messageField) {
  try {
    const dialog = store.getters.getCurrentDialog;

    var msg = {
      type: dialog.type === CONSTANTS.DIALOG_TYPES.PRIVATECHAT ? "chat" : "groupchat",
      body: messageField,
      extension: {
        save_to_history: 1,
        dialog_id: dialog._id,
      },
      markable: 1,
    };
    // update message list
    const message = await fillNewMessageParams(0, msg)
    const newMessage = await setMessages(message)
    store.dispatch("pushNewMessage", newMessage)
    setTimeout(() => {
      scrollTo(document.querySelector('.dialog-chat-messages-list'), 'bottom');
    }, 200);
  } catch (e) {
    console.log("Quckblox Send System Message Error :", e);
  }
}

async function sendMessage(messageField, attachment, pushNotification = true) {
  try {
    const dialog = store.getters.getCurrentDialog

    var msg = {
      type: dialog.type === CONSTANTS.DIALOG_TYPES.PRIVATECHAT ? "chat" : "groupchat",
      body: messageField,
      extension: {
        save_to_history: 1,
        dialog_id: dialog._id,
      },
      markable: 1,
    };

    if(attachment) {
      msg.extension["attachments"] = attachment;
    }
    
    const selfQbId = store.getters.getUserData.quickblox_id;
    const jidOrUserId = getRecipientUserId(dialog.occupants_ids) || dialog.xmpp_room_jid
  
    msg.id = QB.chat.send(jidOrUserId, msg) 

    // send read status when send message if there's unread chat from opponent
    let unreadChat = [];
    Object.values(store.getters.getMessageList).map((el) =>
      el.filter((rel) => {
        if (!rel.all_read && rel.sender_id != selfQbId)
          unreadChat.push(rel);
      })
    );
    if (unreadChat.length) {
      unreadChat.forEach(el => sendReadStatus(el.sender_id, el._id, el.chat_dialog_id));
      store.dispatch("decreaseUnreadCounter", dialog._id);
    }

    // update message list & current dialog
    const message = await fillNewMessageParams(selfQbId, msg)
    const newMessage = await setMessages(message)
    store.dispatch("setDialogParams", message); // Update current dialog info after send message
    store.dispatch("pushNewMessage", newMessage)
    setTimeout(() => {
      scrollTo(document.querySelector('.dialog-chat-messages-list'), 'bottom');
    }, 200);

    if (pushNotification == false) return
    // Send firebase messaging push notification
    if (/WHIZPVL/i.test(messageField)) {
      // Preview link (product / order  )
      const previewLinkType = messageField.split("#")[0] == "WHIZPVL" ? "product" : "order"
      const customMessage = previewLinkType == "product" ? `Product: ${messageField.split("#")[3]}` : `Order: ${messageField.split("#")[1]}`
      firebase(axios).sendFCM(customMessage)
    } else {
      // Image or text
      firebase(axios).sendFCM(attachment ? 'Shared an image' : messageField)
    }
  } catch (e) {
    console.log("Quckblox Send Message Error :", e);
  }
}

function createAndUpload(file) {
  const fileParams = {
    name: file.name,
    file: file,
    type: file.type,
    size: file.size,
    public: false,
  };
  QB.content.createAndUpload(fileParams, (err, res) => {
    if(!err) {
      const attachment = [{  id: res.uid, type: CONSTANTS.ATTACHMENT.TYPE }]
      sendMessage("[attachment]", attachment)
    }
  }) 
}

async function fillNewMessageParams(userId, msg) {
  const message = {
    _id: msg.id,
    attachments: [],
    created_at: Math.round(msg.extension.date_sent || Date.now() / 1000),
    date_sent: Math.round(msg.extension.date_sent || Date.now() / 1000),
    delivered_ids: [userId],
    message: msg.body,
    read_ids: [userId],
    sender_id: userId,
    chat_dialog_id: msg.extension.dialog_id, 
    selfReaded: userId === store.getters.getUserData.quickblox_id || userId == 0,
    read: 0,
  }
  if (msg.extension.attachments) {
    message.attachments = msg.extension.attachments
  
  } 
  if (msg.extension.notification_type) {
    message['notification_type'] = msg.extension.notification_type;
  }

  if (msg.extension.new_occupants_ids) {
    message['new_occupants_ids'] = msg.extension.new_occupants_ids;
  }

  message['status'] = (userId !== store.getters.getUserData.quickblox_id) ? getMessageStatus(message) : undefined;

  return message
}

function getMessageStatus(message) {
  const userId = store.getters.getUserData.quickblox_id
  if (message.sender_id !== userId) {
    return undefined;
  }
  const deleveredToOcuupants = message.delivered_ids.some((id) => id !== userId);
  const readedByOccupants = message.read_ids.some((id) => id !== userId);

  return !deleveredToOcuupants ? 'sent' :
  readedByOccupants ? 'read' : 'delivered';
}

function sendDeliveredStatus(userId, messageId, dialogId) {
  QB.chat.sendDeliveredStatus({
    userId: userId,
    messageId: messageId,
    dialogId: dialogId,
  });
}

function sendReadStatus(userId, messageId, dialogId) {
  QB.chat.sendReadStatus({
    userId: userId,
    messageId: messageId,
    dialogId: dialogId,
  });
}
// TODO perlu di rework supaya bisa return result (jangan pakai resolve)
function getUserList(args, resolve) {
  var searchParams = {
      filter: {
        field: args.field || 'full_name',
        param: 'in',
        value: args.value || [args.full_name || '']
      },
      order: args.order || {
        field: 'updated_at',
        sort: 'desc'
      },
      page: args.page || 1,
      per_page: args.per_page || 100
  };
    
  QB.users.listUsers(searchParams, function(error, result) {
      if(error) console.log("Get User List Error");
      else {
        const users = result.items.map((userObj) => {
          return userObj.user;
        });
        resolve(users)
      }
  });
}

function getRecipientUserId(users) {
  if (users.length === 2) {
    return users.filter(function (user) {
      if (user !== store.getters.getUserData.quickblox_id) {
        return user;
      }
    })[0];
  }
}

function scrollTo(elem, position) {
  const
    elemHeight = elem.offsetHeight,
    elemScrollHeight = elem.scrollHeight;

  if (position === 'bottom') {
    if ((elemScrollHeight - elemHeight) > 0) {
      elem.scrollTop = elemScrollHeight;
    }
  } else if (position === 'top') {
    elem.scrollTop = 10;
  } else if (+position) {
    elem.scrollTop = +position;
  }
}

function clearQb() {
  // Clear dialog list, message list, active dialog
  store.dispatch("setDialogList", {})
  store.dispatch("setMessageList", {})
  store.dispatch("setCurrentDialog", {})
  // reset loading state for quickblox to true
  store.dispatch({
    type: "setIsLoading",
    key: "messageList",
    value: true,
  });
  store.dispatch({
    type: "setIsLoading",
    key: "dialogList",
    value: true,
  });

  // Clear unread message counter on document title
  // pattern regex for : (n)
  const pattern = /^\(\d+\)/;
  if (pattern.test(document.title)) {
    document.title = document.title.replace(
      pattern,
      ""
    );
  }

  QB.chat.disconnect();
}