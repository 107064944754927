export const scroll = {
  scrollTo(id, percentage = 50, behavior = "smooth", scrollWindow) {
    const yOffset = (percentage / 100) * window.innerHeight * -1
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if (scrollWindow) document.getElementById(scrollWindow).parentElement.scrollTop = y
    else window.scrollTo({top: y, behavior: behavior});
  },
  scrollActive(status) {
    if(!status) document.body.classList.add("stop-scrolling")
    else document.body.classList.remove("stop-scrolling")
  },
};
