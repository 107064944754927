import axios from "axios";
import store from "../../store";

const apiUrl = process.env.VUE_APP_API_URL
const apiKey = process.env.VUE_APP_API_KEY
const token = () => store.getters.getUserToken;

export const resellerService = {
  getBankResellerList,
  signUpReseller,
  updateReseller,
  getReferral,
  getSalesOrder,
  getSalesOrderReport,
}


async function getBankResellerList() {
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "users/cms/bank_reseller", {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

async function signUpReseller(signUpData) {
  try {
    const response = await axios({
      method: "POST",
      url: process.env.VUE_APP_API_URL + "users/session/signup_reseller_membership",
      headers: {
        "Content-Type": "multipart/form-data",
        api_key: process.env.VUE_APP_API_KEY,
      },
      data: signUpData,
    });
    setMember();
    return response;
  } catch (error) {
    removeToken();
    return error.response;
  }
}

async function updateReseller(resellerData) {
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "users/profile/update_reseller_file",
      resellerData, {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: store.getters.getUserToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    getProfile()
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

function setMember(member) {
  if (member) store.dispatch("setUserData", member)  
}

function setProfile(profile) {
  if (profile) store.dispatch("setResellerData", profile)
}

async function getProfile() {
  const token = store.getters.getUserToken;
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "users/profile/member_reseller", {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: token,
        },
      }
    );
    const profile = response.data.data.reseller_data
    setProfile(profile);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

// Get Referral Report by Reseller Account
async function getReferral() {
  const token = store.getters.getUserToken;
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "users/reseller/referral_list", {
        headers: {
          api_key: process.env.VUE_APP_API_KEY,
          token: token,
          "Content-Type": "application/json"
        }
      }
    )
    return response
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

function removeToken() {
  store.dispatch("resetUserState");
}

// Get Sales Order List by Reseller Account
async function getSalesOrder(param) {
  try {
    let url =
      apiUrl +
      "users/reseller/order_list" +
      "?offset=" +
      param.offset +
      "&limit=" +
      param.limit;

    if (param.start_date) url = url + "&start_date=" + param.start_date;
    if (param.end_date) url = url + "&end_date=" + param.end_date;
    if (param.order_number) url = url + "&order_number=" + param.order_number;

    const response = await axios.get(url, {
      headers: {
        api_key: apiKey,
        token: token(),
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

// Get Sales Order List by Reseller Account
async function getSalesOrderReport(data) {
  try {
    const response = await axios.post(
      apiUrl + "users/reseller/order_list_report", data, {
        headers: {
          api_key: apiKey,
          token: token(),
          "Content-Type": "application/json"
        }
      }
    )
    return response
  } catch (error) {
    console.error(error);
    return error.response;
  }
}